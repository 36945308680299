import React from "react";
import {
  observability,
  gnc,
  efficiency,
  configuration,
  house,
  CloudCost,
  licenseManagement,
  SiraNavIcon
} from "../assets/images/index";

const SignIn = React.lazy(() => import("../features/auth/SignIn"));
const Version = React.lazy(() => import("../VersionTable.js"));
const ConfigurationPage = React.lazy(() => import("../features/auth/ConfigurationPage"));
const LicenseBackend = React.lazy(() => import("../features/auth/LicenseBackend"));
const HomePage = React.lazy(() =>
  import("../features/homePage/HomePageLanding.js")
);

const CloudCostInsights = React.lazy(() =>
  import("../features/gnc/CloudCostInsights/Landing.js")
);
const LicenseManagement = React.lazy(() =>
  import("../features/gnc/LicenseManagement/LincenseLanding")
);
const LicenseEntitlement = React.lazy(() =>
  import("../features/gnc/LicenseManagement/LicenseEntitleMent/LicenseEntitlement")
);


const SecurityHubFindings = React.lazy(() =>
  import(
    "../features/gnc/complianceCheck/securityHub/securityHubFindingsLandings"
  )
);
const CloudScape = React.lazy(() =>
  import("../features/gnc/complianceCheck/CloudScape/cloudScapeLandings.js")
);
const CisoSHFLanding = React.lazy(() =>
  import("../features/gnc/cisoDashboard/CisoSHFLanding.js")
);
const CisoCloudScapeLanding = React.lazy(() =>
  import("../features/gnc/cisoDashboard/CisoCloudScapeLanding.js")
);

const CCIBudgetCost = React.lazy(() =>
  import("../features/gnc/CloudCostInsights/BudgetCostLanding.js")
);
const AppConfig = React.lazy(() =>
  import("../features/configuration/cmdb/appConfig/AppConfig.js")
);
// const AppConfigProjects = React.lazy(() =>
//   import("../features/configuration/cmdb/appConfig/Projects")
// );

const AppConfigServers = React.lazy(() =>
  import("../features/configuration/cmdb/appConfig/Servers")
);
const LicenseConfigpage = React.lazy(() =>
  import("../features/configuration/cmdb/licenseconfig")
);
// const NewProvisionpage = React.lazy(() =>
//   import("../features/configuration/cmdb/newProvision")
// );
const ApplicationConfiguration = React.lazy(() =>
  import("../features/configuration/cmdb/CmdbApplicationConfiguration")
);
// const AppConfigUsers = React.lazy(() =>
//   import("../features/configuration/cmdb/appConfig/users")
// );
const AppConfigAccountId = React.lazy(() =>
  import("../features/configuration/cmdb/appConfig/accountIds")
);
// const OnBoardingTools = React.lazy(() =>
//   import("../features/efficiency/OnBoarding/OnBoardingTools")
// );
// const AccessManagement = React.lazy(() =>
//   import("../features/efficiency/accessManagement/AccessManagement")
// );
// const MyRequest = React.lazy(() =>
//   import("../features/efficiency/myRequest/MyRequest")
// );
// const MyApproval = React.lazy(() =>
//   import("../features/efficiency/myApproval/MyApproval")
// );
const AuditLog = React.lazy(() =>
  import("../features/efficiency/auditLog/AuditLog")
);

const Tools = React.lazy(() =>
  import("../features/configuration/settings/tools/Tools")
);

const CloudCostConfig = React.lazy(() =>
  import("../features/configuration/settings/CloudCostConfig/CloudCostConfig")
);

const Business = React.lazy(() =>
  import("../features/configuration/cmdb/BusinessInfo/BusinessInfoConfig.js")
);

const OrganizationInfo = React.lazy(() =>
  import(
    "../features/configuration/cmdb/Organizational-Info/OrganizationalInfo"
  )
);

const User = React.lazy(() =>
  import("../features/configuration/cmdb/UserInfoConfig")
);

const SoftwareInventory = React.lazy(() =>
  import("../features/configuration/cmdb/SoftwareInventory/SoftwareInventory.js")
);

const Devices = React.lazy(() =>
  import("../features/configuration/cmdb/MasterDevices/Devices.js")
);
const Serverconfig = React.lazy(() =>
  import("../features/configuration/cmdb/Serverconfig")
);
const SoftwareGroup = React.lazy(() =>
  import("../features/configuration/cmdb/SoftwareGroup/SoftwareGroup.js")
);
const ESXLanding = React.lazy(() =>
  import("../features/configuration/cmdb/ESX/ESXLanding.js")
);
const Cloudaccount = React.lazy(() =>
  import("../features/configuration/cmdb/cloudaccounttable/cloudaccount")
);
//Tools coonfiguration Roles
// const Groups = React.lazy(() =>
//   import("../features/configuration/settings/groups/Groups")
// );
const PIIDataConfiguration = React.lazy(() =>
  import(
    "../features/configuration/settings/PIIConfiguration/PIIConfigurationContainer"
  )
);

// const EmailScheduleContainer = React.lazy(() =>
//   import(
//     "../features/configuration/settings/EmailScheduleConfiguration/EmailScheduleContainer"
//   )
// );
// const DataImportScheduler = React.lazy(() =>
//   import(
//     "../features/configuration/settings/DataImportScheduler/DataImportLanding"
//   )
// );
const Schedulers = React.lazy(() =>
  import(
    "../features/configuration/settings/Schedulers/ManageSchedulers.js"
  )
);
const LdapConfig = React.lazy(() =>
  import("../features/configuration/admin/ldapConfig/LdapConfig")
);
const SmtpConfig = React.lazy(() =>
  import("../features/configuration/admin/smtpConfig/SmtpConfig")
);
const LicenseConfig = React.lazy(() =>
  import("../features/configuration/admin/licenseConfig/LicenseConfig")
);
const UserManagement = React.lazy(() =>
  import("../features/configuration/admin/userManagement/UserManagement")
);
const UserRoleMap = React.lazy(() =>
  import("../features/configuration/admin/UserRoleMap/UserRoleTable")
);
const LoggingConfig = React.lazy(() =>
  import("../features/configuration/admin/loggingConfig/LoggingConfig")
);

const SirarLandings = React.lazy(() =>
  import("../features/Sirar/SirarLandings.js")
);
const SirarCisoLandings = React.lazy(() =>
  import("../features/SirarCiso/CisoLandings.js")
);
const SirarCisoTLLandings = React.lazy(() =>
  import("../features/SirarTLReview/CisoLandings.js")
);



export const authRoutes = [
  {
    name: "SignIn",
    path: "/logout",
    component: <SignIn />,
    key: 0,
  },
  {
    name: "SignIn",
    path: "/auth",
    component: <SignIn />,
    key: 0,
  },
  {
    name: "Version",
    path: "/version",
    component: <Version />,
    key: 0,
  },
  {
    name: "LicenseBackend",
    path: "/LicenseBackend",
    component: <LicenseBackend />,
    key: 0,
  },
  {
    name: "ConfigurationPage",
    path: "/ConfigurationPage",
    component: <ConfigurationPage />,
    key: 0,
  },
];

export const routes = [
  //CODE8 Home page
  {
    name: "Home",
    path: "home",
    component: <HomePage />,
    key: "5",
    icon: house,
    iconNavigation: true,
    childNavs: [
      {
        name: "Home",
        text: "Home",
        path: "home",
        component: <HomePage />,
        key: "5.1",
        navigationDisable: true,
        isNotSideBarNavigation: true,
      },
      {
        name: "Business Info",
        path: "home/BusinessInfoConfig",
        component: <Business />,
        key: "5.3",
        isNotSideBarNavigation: true,
      },
      {
        name: "Server",
        path: "home/:appName/DeviceConfig",
        isNotSideBarNavigation: true,
        key: "5.4",
        component: <AppConfigServers />,
      },
      {
        name: "Account IDs",
        path: "home/:appName/accountIDs",
        isNotSideBarNavigation: true,
        key: "5.5",
        component: <AppConfigAccountId />,
      },
      // {
      //   name: "Users",
      //   path: "home/:appName/users",
      //   isNotSideBarNavigation: true,
      //   key: "5.6",
      //   component: <AppConfigUsers />,
      // },
    ],
  },

  //CODE8 Governance and Compliance
  {
    name: "Governance and Compliance",
    path: "gnc",
    component: <LicenseManagement />,
    key: "2",
    icon: gnc,
    //iconNavigation:true,
    toolTipCustomClass: "hovertipgnc",
    childNavs: [
      {
        name: "Compliance",
        text: "Compliance",
        path: "gnc/compliance/CloudScapeFindings",
        component: <CloudScape />,
        navigationDisable: false,
        key: "2.2",
        childNavs: [
          {
            name: "CISO Dashboard",
            text: "CISO Dashboard",
            path: "gnc/CISOInsights/securityHubFindings",
            component: <CisoSHFLanding />,
            key: "2.2.2",
          },
          {
            name: "CISO Dashboard",
            text: "CISO Dashboard",
            path: "gnc/CISOInsights/CloudScapeFindings",
            component: <CisoCloudScapeLanding />,
            key: "2.2.3",
          },

          {
            name: "Security Hub Findings",
            text: "Security Hub Findings",
            path: "gnc/compliance/securityHub/securityHubFindingsLandings",
            component: <SecurityHubFindings />,
            key: "2.2.5",
          },
          {
            name: "CloudScape Findings",
            text: "CloudScape Findings",
            path: "gnc/compliance/CloudScapeFindings",
            component: <CloudScape />,
            key: "2.2.6",
          },
      
        ],
      },
      {
        name: "Ciso Insights",
        text: "CISO Insights",
        path: "gnc/CISOInsights/CloudScapeFindings",
        component: <CisoCloudScapeLanding />,
        key: "2.3",
      },
      // {
      //   name: "License Management",
      //   text: "License Management",
      //   //isNotSideBarNavigation: true,
      //   path: "gnc/Summary",
      //   component: <LicenseManagement />,
      //   key: "2.5",
      // },
    ],
  },

  {
    name: "Cloud Cost",
    path: "cloudCost",
    component: <CloudCostInsights />,
    key: "3",
    icon: CloudCost,
    iconClass:"cloudCostWidth",
    iconNavigation: false,
    childNavs: [
      {
        name: "Insights",
        text: "Insights",
        path: "cloudCost/Insights",
        component: <CloudCostInsights />,
        key: "3.1",
        navigationDisable: false,
        isNotSideBarNavigation: false,
      },
      {
        name: "Cost Overview",
        text: "Cost Overview",
        path: "cloudCost/CostOverview",
        component: <CCIBudgetCost />,
        key: "3.1.1",
        isNotSideBarNavigation: true,
      },
    ],
  },
 //License Management
 {
  name: "Software License Management",
  path: "softwareLicenseManagement",
  component: <CloudCostInsights />,
  key: "4",
  icon: licenseManagement,
  iconNavigation: false,
  childNavs: [
    {
      name: "License Management",
      text: "License Management",
      path: "softwareLicenseManagement/Summary",
      // path: "licenseManagement/Summary",
      component: <LicenseManagement />,
      key: "4.1",
      customDropContainerClass: "CMDB",
    },
    {
      name: "Software Group",
      text: "Software Group",
      path: "softwareLicenseManagement/softwareGroup",
      component: <SoftwareGroup />,
      key: "4.3",
    },
    {
      name: "Software List",
      text: "Software List",
      path: "softwareLicenseManagement/SoftwareList",
      component: <SoftwareInventory />,
      key: "4.2",
    },
    {
      name: "License Entitlement",
      text: "License Entitlement",
      path: "softwareLicenseManagement/licenseManagement",
      component: <LicenseEntitlement />,
      key: "2.2.6",
      isNotSideBarNavigation: true,
     
    },
    {
      name: "gnc",
      text: "License Management",
      isNotSideBarNavigation: true,
      path: "/softwareLicenseManagement/licenseManagement/licenseconfigpage",
      component: <LicenseConfigpage />,
      key: "2.5",
    },
    {
      name: "ESX Group",
      text: "ESX Group",
      path: "softwareLicenseManagement/ESXGroup",
      component: <ESXLanding />,
      key: "4.4",
    }
  ],
},
{
  name: "SIRAR",
  path: "Sirar",
  component: <SirarLandings />,
  key: "5",
  icon: SiraNavIcon,
  iconNavigation: true,
  childNavs: [
    {
      name: "SIRAR Dashboard",
      text: "SIRAR Dashboard",
      path: "Sirar",
      component: <SirarLandings />,
      key: "5.1",
      // navigationDisable: true,
      // isNotSideBarNavigation: true,
    },
    // {
    //   name: "TL Reviewer Insight",
    //   text: "TL Reviewer Insight",
    //   path: "Sirar/TLReviewerInsights",
    //   component: <SirarCisoTLLandings />,
    //   key: "5.2",
    // },
    {
      name: "Log Approver Insights",
      text: "Log Approver Insights",
      path: "Sirar/logApproverInsights",
      component: <SirarCisoLandings />,
      key: "5.3",
    },
  ],
},

  //CODE8 Configuration
  {

    name: "Configuration",
    path: "configuration",
    component: <Tools />,
    key: "5",
    icon: configuration,
    childNavs: [{
      name: "CMDB",
      text: "CMDB",
      path: "configuration/cmdb",
      //navigationDisable: true,
      customDropContainerClass: "CMDB",
      component: <AppConfig/>,
      key: "5.11",
      childNavs: [
        {
          name: "Business Info",
          text: "Business Info",
          path: "configuration/cmdb/BusinessInfoConfig",
          component: <Business />,
          key: "5.11.1",
        },
        {
          name: "Role Allocation",
          text: "Role Allocation",
          path: "configuration/cmdb/BusinessInfoConfig/roleAllocation",
          component: <OrganizationInfo />,
          key: "5.11.2",
          isNotSideBarNavigation: true,
        },

        // {
        //   name: "Users",
        //   text: "Users",
        //   path: "configuration/cmdb/masterlist/UserInfoConfig",
        //   component: <User />,
        //   key: "4.11.3",
        // },
        
        {
          name: "Cloud Accounts",
          text: "Cloud Accounts",
          path: "configuration/cmdb/masterlist/cloudaccount",
          component: <Cloudaccount />,
          key: "5.11.4",
        },
        {
          name: "Devices",
          text: "Devices",
          path: "configuration/cmdb/masterlist/Devices",
          component: <Devices />,
          key: "5.11.5",
        },

        {
          name: "AppConfig",
          // text: "App Config",
          path: "configuration/cmdb",
          isNotSideBarNavigation: true,
          component: <AppConfig />,
          key: "5.11.6",
        },
        // {
        //   name: "Software List",
        //   text: "Software List",
        //   path: "configuration/cmdb/SoftwareList",
        //   component: <SoftwareInventory />,
        //   key: "5.11.7",
        // },
        // {
        //   name: "Software Group",
        //   text: "Software Group",
        //   path: "configuration/cmdb/softwareGroup",
        //   component: <SoftwareGroup />,
        //   key: "5.11.4",
        // },
        // {
        //   key: "4.11.7",
        //   name: "Project",
        //   path: "/configuration/cmdb/:appname/project",
        //   isNotSideBarNavigation: true,
        //   component: <AppConfigProjects />,
        // },
        {
          key: "5.11.8",
          name: "Server",
          path: "configuration/cmdb/:appName/DeviceConfig",
          isNotSideBarNavigation: true,
          component: <AppConfigServers />,
        },
        // {
        //   key: "4.11.9",
        //   name: "Users",
        //   path: "configuration/cmdb/:appName/users",
        //   isNotSideBarNavigation: true,
        //   component: <AppConfigUsers />,
        // },
        {
          key: "5.11.10",
          name: "Account IDs",
          path: "configuration/cmdb/:appName/accountIDs",
          isNotSideBarNavigation: true,
          component: <AppConfigAccountId />,
        },
        {
          key: "5.11.11",
          name: "Application Configuration",
          text: "Application Configuration",
          path: "configuration/cmdb/applicationConfiguration",
          isNotSideBarNavigation: true,
          component: <ApplicationConfiguration />,
        },
      ],
    },
    {
      key: "6.0",
      name: "License Config",
      path: "configuration/cmdb/Devices/licenseconfigpage",
      isNotSideBarNavigation: true,
      component: <LicenseConfigpage />,
    },
    // {
    //   key: "7.0",
    //   name: "Infra Provisioning",
    //   path: "configuration/cmdb/Devices/newProvisionpage",
    //   isNotSideBarNavigation: true,
    //   component: <NewProvisionpage />,
    // },
    {
      name: "Settings",
      text: "Settings",
      path: "configuration/settings/tools",
      navigationDisable: true,
      customDropContainerClass: "settings",
      component: <Tools />,
      key: "5.1",
      childNavs: [
        {
          name: "Cloud Cost",
          text: "Cloud Cost",
          path: "configuration/settings/CloudCost",
          component: <CloudCostConfig />,
          key: "5.1.3",
        },
        {
          name: "Schedulers",
          text: "Schedulers",
          path: "configuration/settings/Schedulers",
          component: <Schedulers />,
          key: "5.1.1",
        },
        {
          name: "Tools",
          text: "Tools",
          path: "configuration/settings/tools",
          component: <Tools />,
          key: "5.1.2",
        },
        // {
        //   name: "Groups",
        //   text: "Groups",
        //   path: "configuration/settings/groups",
        //   component: <Groups />,
        //   key: "4.1.3",
        // },
        {
          name: "PII Data Configuration",
          text: "PII Data Configuration",
          path: "configuration/settings/PIIDataConfiguration",
          isNotSideBarNavigation: true,
          component: <PIIDataConfiguration />,
          key: "5.1.4",
        },

      ],
    },
    {
      name: "Administration",
      text: "Administration",
      path: "configuration/administration/directoryConfiguration",
      navigationDisable: true,
      customDropContainerClass: "admin",
      component: <LdapConfig />,
      key: "5.2",
      childNavs: [
        {
          name: "UserManagement",
          text: "User Management",
          path: "configuration/administration/userManagement",
          component: <UserManagement />,
          key: "5.2.4",
        },
        {
          name: "SmtpConfig",
          text: "SMTP Configuration",
          path: "configuration/administration/smtpConfig",
          component: <SmtpConfig />,
          key: "5.2.2",
        },
        {
          name: "Directory Configuration",
          text: "Directory Configuration",
          path: "configuration/administration/directoryConfiguration",
          component: <LdapConfig />,
          key: "5.2.1",
        },
        {
          name: "LicenseConfig",
          text: "License Configuration",
          path: "configuration/administration/licenseConfig",
          component: <LicenseConfig />,
          key: "5.2.3",
        },
        {
          name: "UserRoleMap",
          text: "Role Mapping",
          isNotSideBarNavigation: true,
          path: "/configuration/administration/userManagement/rolemapping",
          component: <UserRoleMap />,
          key: "5.2.5",
        },
        {
          name: "LoggingConfig",
          text: "Logging Configuration",
          path: "configuration/administration/loggingConfig",
          component: <LoggingConfig />,
          key: "5.2.5",
        },

      ],
    },
    {
      name: "AuditLog",
      text: "Audit Log",
      path: "configuration/auditLog",
      component: <AuditLog />,
      key: "4.2.6",
    },


    ],

  },


];
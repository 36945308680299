import React from "react";
import { Modal, ModalHeader } from "react-bootstrap";
import "./custom-components.style.scss";

const CustomModal = (props) => {
  const {
    modalOpen,
    modalHide,
    closeBtn,
    modalCustomClass,
    modalBody,
    closeBtnFuc,
    size,
  } = props;
  return (
    <Modal
      show={modalOpen}
      onHide={modalHide}
      centered
      dialogClassName={modalCustomClass}
      animation={true}
      size={size}
      backdrop="static"
    >
      {closeBtn && <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => closeBtnFuc()}>×</button>}
      <Modal.Body>{modalBody(props)}</Modal.Body>
    </Modal>
  );
};

export default CustomModal;
